<template>
  <div class="flex items-center justify-center gap-1">
    <UiIcon name="sale-label" class="size-6" />
    <p class="ui-text-sm capitalize">
      <span class="font-bold italic">{{ $t("finalSaleText") }}</span
      >&nbsp;
      <span class="font-light not-italic underline">
        {{ $t("nonRefundable") }}
      </span>
    </p>
  </div>
</template>
